<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-form>
        <b-container fluid>
          <b-row>

            <!-- Field: Full Name -->
            <b-col
              cols="4"
            >
              <b-form-group
                label="Name"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="factoryData.name"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Role -->
            <b-col
              cols="4"
            >
              <b-form-group
                label="Type"
                label-for="user-role"
              >
                <v-select
                  v-model="factoryData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="4"
            >
              <b-form-group
                label="Customer"
                label-for="customerID"
                :state="factoryData.customerID ? true : false"
              >
                <v-select
                  v-model="factoryData.customerID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customerList"
                  label="name"
                  :reduce="val => val.customerID"
                  :clearable="false"
                  input-id="customerID"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row class="mt-1 mb-2">
            <b-col
              cols="12"
            >
              <label>Alert Subscriptions</label>
              <b-row class="bg-light border-light ml-1 mr-1 p-1">
                <b-col>
                  Notifications
                </b-col>
                <b-col>
                  Enable
                  <input
                    id="selectallalerts"
                    type="checkbox"
                    tabindex="0"
                    @click="toggleSelectAll"
                  >
                </b-col>
              </b-row>
              <b-row
                v-for="(item,index) in alertList"
                :key="index"
                class="border-light ml-1 mr-1 p-1"
              >
                <b-col>
                  {{ item.faultDescription }}
                </b-col>
                <b-col>
                  <b-form-checkbox
                    :key="item.faultCode"
                    v-model="factoryData.subscriptions"
                    :value="item.faultCode"
                    name="flavour-4a"
                    inline
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Teams Channel URL"
                label-for="teams-channel-url"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <b-form-checkbox
                      v-model="factoryData.enableTeams"
                      name="checkbox-input"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    id="teams-channel-url"
                    v-model="factoryData.teamsChannelURL"
                    placeholder="Teams Channel Web Hook URL"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="updateFactory"
      >
        Save Changes
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Reset
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardBody, BInputGroupPrepend, BFormCheckbox, BInputGroup, BContainer,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import customer from '@/store/customer'
import factory from '@/store/factory'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    BInputGroupPrepend,
    BFormCheckbox,
    BInputGroup,
    BContainer,
    vSelect,
  },
  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'
    const FACTORY_APP_STORE_MODULE_NAME = 'app-factory'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customer)
    if (!store.hasModule(FACTORY_APP_STORE_MODULE_NAME)) store.registerModule(FACTORY_APP_STORE_MODULE_NAME, factory)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
      if (store.hasModule(FACTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(FACTORY_APP_STORE_MODULE_NAME)
    })
    const factoryID = router.currentRoute.params.id
    const toast = useToast()
    const customerList = ref([])
    const alertList = ref([])
    const factoryData = ref({
      id: '',
      name: '',
      type: '',
      customerID: '',
      subscriptions: [],
      enableTeams: false,
      teamsChannelURL: '',
    })

    const typeOptions = [
      { label: 'Site', value: 'site' },
      { label: 'Building', value: 'building' },
      { label: 'Line', value: 'line' },
    ]

    const getFaultCodes = () => {
      store.dispatch('app-factory/fetchFaultCodes')
        .then(response => {
          alertList.value = response.data
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in retrieving fault codes list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    getFaultCodes()

    const fetchAllCustomers = () => {
      store.dispatch('app-customer/fetchAllCustomers')
        .then(response => {
          customerList.value = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Customers',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetchAllCustomers()

    const fetchFactory = () => {
      store.dispatch('app-factory/fetchAllFactory')
        .then(response => {
          const { data } = response
          // eslint-disable-next-line array-callback-return
          data.filter(item => {
            if (item.id === factoryID) {
              factoryData.value = item
              console.log(factoryData.value)
            }
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Factory',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetchFactory()

    const updateFactory = () => {
      store.dispatch('app-factory/updateFactory', factoryData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Item Updated Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Updating Item',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const toggleSelectAll = () => {
      const selectAll = document.getElementById('selectallalerts')
      console.log(selectAll.checked)
      if (selectAll.checked) {
        factoryData.value.subscriptions = [...alertList.value.map(alert => alert.faultCode)]
      } else {
        factoryData.value.subscriptions = []
      }
    }

    return {
      typeOptions,
      customerList,
      factoryData,
      alertList,
      toggleSelectAll,
      updateFactory,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.table-scroll{
    overflow: auto;
    max-height: 65vh;
}
</style>
